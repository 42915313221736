
<template>
  <div class="my-5 pb-5" >
    <div style="margin-top: 70px !important" class="bg-danger">
    </div>
    <div class="clearfix"></div>

    <b-container class="pt-5">
      <b-row>

        <b-col cols="12" class="mb-5">
          <div>
            <h3 class="mb-1">ฟังย้อนหลัง</h3>
            <div class="short--line"></div> <br>
          </div>
        </b-col>

        <b-col class="mb-3" md="3" v-for="item in category" :key="item._id">
      
          <b-card
            :title="item.name"
            :img-src="url+'/'+item.image"
            class="shadow pointer"
            img-alt="Image"
            img-top 
            @click="viewHistory(item._id)">
           
          </b-card>

        </b-col>
      </b-row>
    </b-container>

    

  

  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'home',
  data () {
    return {
      category: [],
      url: process.env.VUE_APP_DOMAIN_IMAGE
    }
  },
  methods: {
    ...mapActions('website', ['getHistoryCategory']),
    viewHistory ( id ) {
      this.$router.push(`/radio/history/${id}`)
    }
    
  },
  async mounted () {
    
    let res = await this.getHistoryCategory();

    if ( res.success ) {  
      this.category = res.data
    }

  }
}
</script>

<style >


  .pointer {
    cursor: pointer;
  }
  .short--line {
    width: 50px;
    height: 2px;
    background: #f26726;
  }
</style>

<style scoped>
.txt--date {
  font-size: 13px;
  color: #aca9a9;
}
</style>      
