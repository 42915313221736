
<template>
  <div class="my-5 pb-5" > 
   

    <div style="margin-top: 70px !important" class="bg-danger">
    </div>


    <img v-if="banner != ''" :src="url+'/'+banner" alt="" srcset="" width="100%">

    <b-container>
      <b-row>
        <b-col cols="12" class="mt-5 ">
          <div>
            <h3 class="mb-1">Think Studio</h3>
            <div class="short--line"></div>
          </div>
        </b-col>

        <b-col md="12" class="mt-4 mb-3">
          <div>
            <b-form-select @change="changeSelect" v-model="selected" :options="options"></b-form-select>
          </div>
        </b-col>
        
      
        <b-col v-if="blogs.length == 0">
          <div class="text-center">
            *** ยังไม่มีข้อมูลในหน้านี้ ***
          </div>
        </b-col>

        <b-col class="mb-4 pointer" cols="12">
          <b-card-group columns="">
            <b-card
              v-for="(item, index) in blogs" :key="index"
              :title="item.title"
              :img-src="url+'/'+item.image"
              class="shadow"
              img-alt="Image"
              img-top 
              @click="viewBlog(item._id)">
              <b-card-text>
                <div class="txt--date">
                  {{ item.created_at }}
                </div>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
        
      </b-row>
    </b-container>

  </div>

</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      page_type: 'think_studio',
      url: process.env.VUE_APP_DOMAIN_IMAGE,
      blogs: [],
      banner: "",
      selected: null,
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'activity_1', text: 'กิจกรรม' },
        { value: 'activity_2', text: 'แกลอรี่' },
        { value: 'activity_3', text: 'Online Workshop' },
        { value: 'activity_4', text: 'DIY' },
      ]
    }
  },
  methods: {
    ...mapActions('website', ['blogType', 'getBlogGroup', 'getMainBanner']),
    async viewBlog (id) {
      this.$router.push('/blog/'+id)
    },
    async changeSelect () {

      if ( this.selected == null ) {
        this.listBlog();
      } else {

        let res = await this.getBlogGroup({ type: this.page_type, category: this.selected });
        if (res.success) {
          this.blogs = res.data;
        }
        
      }
      
      

    },
    async listBlog ( ) {

      

     

      let res = await this.blogType({ type: this.page_type, page: this.page++});
      if (res.success) {
        for (let i= 0;i<res.data.length; i++) {
          this.blogs.push(res.data[i])
        }
      }

    },
  },
  async mounted() {
    let banner = await this.getMainBanner( this.page_type )

    if (banner.success ) {
      this.banner = banner.data[0].image
    }
    
    await this.listBlog();
  }
}
</script>

<style scoped>
.txt--date {
  font-size: 13px;
  color: #aca9a9;
}
</style>      
