
<template>
  <div class="my-5 pb-5" >
    <div style="margin-top: 70px !important" class="bg-danger">
    </div>
    <div class="clearfix"></div>

    <b-container class="pt-5">
      <b-row>
        <b-col style="height: 400px" cols="12">
          
          <div>
            <h1 class="mb-1">Radio</h1>
            <div class="short--line"></div> <br>
          </div>

          <div class="player">
        
            <videoPlayer ref="videoPlayer"
              class="vjs-custom-skin"
              :options="playerOptions"
              @play="onPlayerPlay($event)"
              @ready="onPlayerReady($event)">
            </videoPlayer>

          </div>
          <div class="clearfix"></div>
        </b-col>
        <b-col cols="12" style="margin-top: 70px">
          <b-list-group>

            <b-list-group-item 
              v-for="item in radios" :key="item._id" 
              @click="liveSource(item)" 
              class="pointer" >
              <i v-if="item.link == source" class="fas fa-angle-double-right mr-3"></i> {{ item.title }}
            
            </b-list-group-item>
            
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>

    

  

  </div>
</template>

<script>

import {videoPlayer} from 'vue-videojs7'
import { mapActions } from 'vuex'

export default {
  name: 'home',
  components: {
    videoPlayer
  },
  data () {
    return {
      radios: [],
      source: '',
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        },

      }
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    ...mapActions('website', ['getRadio']),

    liveSource (item) {
      this.source = item.link;
      this.playVideo();
    },
    onPlayerPlay (player) {
    },
    onPlayerReady (player) {
      this.player.play()
    },
    stopVideo () {
      this.player.pause();
    },
    playVideo: function () {

      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: this.source
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video)
      // this.player.load()
      this.player.play()
    }
  },
  async mounted () {
    
    let res = await this.getRadio();

    if ( res.success ) {
      this.radios = res.data;
      if (res.data.length > 0) {
        this.source = res.data[0].link
      }
    }



    this.playVideo()
  }
}
</script>

<style >
  .player {
    position: absolute !important;
    left: 0;
    width: 100%;
    height: 400px;
  }
  .vjs-custom-skin {
    padding: 0px 12px;
    height: 400px !important;
    width: 100%;
  }

  .vjs-custom-skin /deep/ .video-js {
    
    height: 100%;
    max-height: 200px;
    width: 100%;
  }
#vjs_video_3 {
  height: 100%;
  width: 100%;
}
  .pointer {
    cursor: pointer;
  }
  .short--line {
    width: 50px;
    height: 2px;
    background: #f26726;
  }
</style>
