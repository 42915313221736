<template>
  <div class="my-5" >
    <div style="margin-top: 70px !important" class="bg-danger">
      
      <img v-if="media.banner != ''" :src="root_url+'/'+media.banner" alt="" width="100%">
    </div>
    <b-container class="mt-5">

      <!-- <b-row>
        <b-col md="6" lg="5" class="mb-5">
          <div class="d-md-none">
            <h3 name="top">Podcast name </h3>
            <div class="text-primary mb-4 h5 text-secondary">Podcast subname</div>
          </div>
          <div class="swiper-products">
            <swiper ref="swiperTop" :options="swiperOptionTop" class="gallery-top">
              <swiper-slide v-for="item in productImages" :key="item.index" :style="{ backgroundImage: `url(${item.img})` }" />
            </swiper>
            <swiper ref="swiperThumbs" :options="swiperOptionThumbs" class="gallery-thumbs">
              <swiper-slide v-for="item in productImages" :key="item.index" :style="{ backgroundImage: `url(${item.img})` }" />
            </swiper>
          </div>
        </b-col>
        <b-col md="6" lg="7" class="mb-5 pl-lg-5">
          <div class="d-none d-md-block">
            <h3 name="top">Podcast name </h3>
            <div class="text-primary mb-4 text-secondary">Podcast subname</div>
          </div>
          <div class="bg-light">
            <div class="mb-2">Detail 1: <span class="sku">N/A</span></div>
            <div class="mb-2">Detail 2: <span class="sku">N/A</span></div>
            <div class="mb-2">Detail 3: <span class="sku">N/A</span></div>
            <div class="mb-2">Categories: <a href="#">Sports Bras</a>, <a href="#">Tops</a></div>
            <div>Tags: <a href="#">Medium Impact</a>, <a href="#">Recommended</a></div>
          </div>
        </b-col>
      </b-row> -->
      <!-- <p class="text-content">และยังคงสภาพเดิมไว้อย่างไม่มีการเปลี่ยนแปลง มันได้รับความนิยมมากขึ้นในยุค ค.ศ. 1960 เมื่อแผ่น Letraset วางจำหน่ายโดยมีข้อความบนนั้นเป็น Lorem Ipsum และล่าสุดกว่านั้น คือเมื่อซอฟท์แวร์การทำสื่อสิ่งพิมพ์ (Desktop Publishing) อย่าง Aldus PageMaker ได้รวมเอา Lorem Ipsum เวอร์ชั่นต่างๆ เข้าไว้ในซอฟท์แวร์ด้วย</p> -->

      <h3>{{media.name}}</h3>
      <p>{{ moment(media.created_at) }} </p>
      <div v-html="media.link"></div>

      <!-- <div class="section--more--podcast">
        <div class="next--podcast border-bottom" v-for="(item, index) in productImages" :key="index">
          <b-row>
            <b-col md="1" class="">
              <img :src="item.img" width="100%">
            </b-col>
            <b-col>
              <div class="h-100 position-relative">
                <h6>R U OK EP.1 เราจำเป็นต้องรู้จัก ‘ตัวตน’ ของตัวเองไหม ในเมื่อตัวเราก็เปลี่ยนไปทุกวัน</h6>
                <p>และยังคงสภาพเดิมไว้อย่างไม่มีการเปลี่ยนแปลง อเมื่อซอฟท์แวร์การทำสื่อสิ่งพิมพ์  ได้รวมเอา Lorem Ipsum เวอร์ชั่นต่างๆ เข้าไว้ในซอฟท์แวร์ด้วย</p>
                <div class="podcast--control">
                  <div class="podcast--control--item">
                  <b-badge size="sm" variant="dark"> 14:10 นาที</b-badge>
                  <b-badge size="sm" variant="dark" class="ml-3 pl-2 pr-2">Size 20K</b-badge>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div> -->

      <div class="text-center my-5 mt-10">
        <!-- <a href="#top" class="text-decoration-none"><i class="far fa-chevron-up" /> Back to top</a> -->
      </div>
    </b-container>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import Moment from 'moment'

export default {
  data () {
    return {
      root_url: process.env.VUE_APP_DOMAIN_IMAGE,
      media: {
        
        banner: "",
        category: "",
        created_at: "",
        deleted: false,
        link: "",
        name: "",
        thumbnail: "",
        type: "",
      },
      swiperOptionTop: {
        spaceBetween: 10
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      },
      productImages: [
        { img: 'https://i1.sndcdn.com/artworks-000244185122-e7fxt5-t500x500.jpg' },
        { img: 'https://i1.sndcdn.com/artworks-000388233219-kbcd8q-t500x500.jpg' },
        { img: 'https://i1.sndcdn.com/artworks-000601747087-u58isd-t500x500.jpg' }
      ]
    }
  },
  methods: {
    ...mapActions('website', ['getMedia']),
    moment ( dt ) {
      return new Moment( dt ).format('MMMM Do YYYY, h:mm:ss a');
    }
  },
  async mounted () {
    this.$nextTick(() => {
      // const swiperTop = this.$refs.swiperTop.swiper
      // const swiperThumbs = this.$refs.swiperThumbs.swiper
      // swiperTop.controller.control = swiperThumbs
      // swiperThumbs.controller.control = swiperTop
    })

    let media = await this.getMedia(this.$route.params.id);

    if (media.success ) {
      this.media = media.data;
    }

  }
}
</script>

<style scoped>
.podcast--control--item {
  position: absolute;
  bottom: 0;
}
.next--podcast {
  padding: 11px;
}
.next--podcast:hover {
  background: #e4e4e4;
}
</style>