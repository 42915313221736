<template>
  <div class="my-5" style="margin-top: 100px !important">
    <b-container>
      <b-row>
        <b-col md="6" lg="5" class="mb-5">
          <div class="d-md-none">
            <h3 name="top">Product name 1</h3>
            <div class="text-primary mb-4 h5">10 ชิ้น ที่เหลืออยู่</div>
          </div>
          <div class="swiper-products">
            <!-- Product slider -->
            <swiper ref="swiperTop" :options="swiperOptionTop" class="gallery-top">
              <swiper-slide v-for="item in productImages" :key="item.index" :style="{ backgroundImage: `url(${item.img})` }" />
            </swiper>
            <!-- Product slider Thumbs -->
            <swiper ref="swiperThumbs" :options="swiperOptionThumbs" class="gallery-thumbs">
              <swiper-slide v-for="item in productImages" :key="item.index" :style="{ backgroundImage: `url(${item.img})` }" />
            </swiper>
          </div>
        </b-col>
        <b-col md="6" lg="7" class="mb-5 pl-lg-5">
          <div class="d-none d-md-block">
            <h3 name="top">{{product.product_name}}</h3>
            <div class="text-primary mb-4">{{product.amount}} ชิ้น ที่เหลืออยู่</div>
          </div>
          
          <div class="p-3 bg-light">
            <div class="mb-2">เขียนเมื่อ: <span class="sku">{{Moment(product.created_at)}}</span></div>
          </div>

          <br>

          <p class="text-content bold" v-html="product.description"></p>
        </b-col>
      </b-row>
      
      <!-- <div class="text-center my-5">
        <a href="#top" class="text-decoration-none"><i class="far fa-chevron-up" /> Back to top</a>
      </div> -->
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

var moment = require('moment');

export default {
  data () {
    return {
      swiperOptionTop: {
        spaceBetween: 10
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      },
      productImages: [],
      product: {
        product_name: "",
        description: "",
        amount: "",
        created_at: ""
      }
    }
  },
  methods: {
    ...mapActions('website', ['getProduct']),
    Moment (date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');;
    }
  },
  async mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })

    let res = await this.getProduct( this.$route.params.id )

    if ( res.success ) {
      let data = res.data;

      for ( let i=0;i<data.image.length; i++) {
        this.productImages.push({
          img: process.env.VUE_APP_DOMAIN_IMAGE +'/' + data.image[i]
        })
      }

      this.product.product_name = data.product_name
      this.product.description = data.description
      this.product.amount = data.amount
      this.product.created_at = data.created_at

      // VUE_APP_DOMAIN_IMAGE

    }

  }
}
</script>
