<template>
<div class="pt-5 pb-5"  >
  <b-container class="my-5">
    <div class="section-title">
      <div class="title-link">Shopping</div>

      <b-container>
        <b-row>
          <b-col
            v-for="item in products"
            :key="item.index"
            sm="6"
            lg="3"
            class="mb-5"
          >
            <b-card class="card-prod shadow" no-body>
              <b-link :to="`/product/${item._id}`" class="stretched-link prod-img" :style="{ backgroundImage: `url(${root_url}/${item.image[0]})` }" />
              <div class="content-prod">
                <b-link class="title">{{ item.product_name }}</b-link>
                <b-badge variant="primary">{{ item.amount }} ชิ้น</b-badge>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  data () {
    return {
      root_url: process.env.VUE_APP_DOMAIN_API + 'images/',
      
    }
  },
  methods: {
    imgUrl (img) {
      return require(`@/assets/images/homepage/blog/${img}.jpg`)
    }
  },
  props: {
    products: Array
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";

.card.card-prod {
  border: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  margin: 0 auto;

  &:hover {
    .prod-img {
      transform: scale(1.3);
    }
  }

  .prod-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 4s ease-out;
  }

  .content-prod {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.3s ease;
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    padding: 1rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    color: #fff;

    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 1rem;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    .badge {
      line-height: 1.5;
    }
  }
}
</style>
