<template>
  <div> 
    <div style="margin-top: 69.75px !important" class="bg-danger">
    </div>
    <img v-if="banner != ''" width="100%" :src="url+banner" alt="">
    <Product :products="products" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Product from '@/views/Product'
export default {
  components: {
    Product
  },
  data () {
    return {
      products: [],
      banner: "",
      url: process.env.VUE_APP_DOMAIN_IMAGE
    }
  },
  methods: {
    ...mapActions('website', ['getProductList'])
  },
  async mounted () {
    let res = await this.getProductList();
    if (res.success) {
      this.products = res.data
      this.banner = res.banner.image
    }
  }
}
</script>