<template>
  <div>
    <router-view />
  </div>
</template>

<script>

// import Navbar from '@/components/navbar.vue'

export default {
  components: {
    // Navbar
  },
  methods: {
    track () {
      this.$ga.page('/')
    }
  }
}
</script>
