<template>
  <div>
    <b-container style="margin-top: 100px !important">
      <h3 class="mt-6 mb-2 text-primary">เตรียมตัวเที่ยว เทศกาลดอกทิวลิป เนเธอร์แลนด์ 2019 ณ สวนเคอเคนฮอฟ หนึ่งปีมีครั้ง!</h3>
      <div>
        <social-sharing
          url="https://vuejs.org/"
          title="The Progressive JavaScript Framework"
          description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
          quote="Vue is a progressive framework for building user interfaces."
          hashtags="vuejs,javascript,framework"
          twitter-user="vuejs"
          inline-template
        >
          <div>
            <network network="facebook">
              <i class="fab fa-facebook facebook-color fa-2x mr-2" />
            </network>
            <network network="line">
              <i class=" fab fa-line line-color fa-2x mr-2" />
            </network>
            <network network="twitter">
              <i class="fab fa-twitter twitter-color fa-2x mr-2" />
            </network>
            <network network="whatsapp">
              <i class="fab fa-whatsapp what-app-color fa-2x mr-2" />
            </network>
          </div>
        </social-sharing>
      </div>
      <span>27 ส.ค. 62 | 15:22 น</span>
      <div class="my-5">
        <img width="100%" src="../../assets/images/news/tewlip1.jpg" alt="">
      </div>
      <p class="lead">วันนี้ มัชรูมทราเวล จะขอนำทุกท่านเดินทางท่องไปยังสวนสวยในฝันแสนโรแมนติก ณ สวนเคอเคนฮอฟ (Keukenhof Garden) เมืองลิเซ่ (Lisse) ประเทศเนเธอร์แลนด์ (Netherlands) สถานที่จัด เทศกาลดอกทิวลิป เนเธอร์แลนด์ เป็นประจำทุกปี และในปีนี้กำลังจะจัดขึ้นในระหว่างวันที่ 21 มีนาคม – 19 พฤษภาคม 2019 ความสวยงามของที่นี่ดึงดูดนักท่องเที่ยวได้มากมายเลยล่ะค่ะ ไม่รอช้า ไปชมดอกไม้สวยๆ กันเลย</p>
      <div class="my-5">
        <img width="100%" src="../../assets/images/news/tewlip2.jpg" alt="">
      </div>
      <p class="lead">เทศกาลดอกทิวลิป เนเธอร์แลนด์ เป็นเทศกาลดอกไม้ที่หนึ่งปีจัดเพียงครั้งเดียวเท่านั้น โดยจะจัดขึ้นในช่วงฤดูใบไม้ผลิ (เดือน มี.ค. – เดือน พ.ค.) ณ สวนเคอเคนฮอฟ สวนสวรรค์ที่เต็มไปด้วยดอกทิวลิปหลากสีสันนับล้านต้น บานสะพรั่งอยู่ทั่วบริเวณ รอต้อนรับนักท่องเที่ยวที่มา ทัวร์เนเธอร์แลนด์ ในช่วงนี้อย่างพร้อมเพรียงกัน</p>
      <div class="my-5">
        <img width="100%" src="../../assets/images/news/tewlip3.jpg" alt="">
      </div>
      <p class="lead"><span class="text-primary">สวนเคอเคนฮอฟ (Keukenhof Garden)</span> ได้ชื่อว่าเป็นสวนดอกไม้ที่มีความสวยงาม และเป็นแหล่งปลูกทิวลิปที่ใหญ่ที่สุดของประเทศเนเธอร์แลนด์ ตั้งอยู่ในเมืองลิซเซ่ (Lisse) ห่างจากกรุงอัมสเตอร์ดัมไม่ไกลนัก สวนแห่งนี้มีพื้นที่กว้างใหญ่กว่า 200 ไร่ โดยมีการแบ่งสรรจัดโซนไว้ได้อย่างลงตัว มีทางเดินที่ร่มรื่น บรรยากาศสดชื่นเขียวขจีด้วยต้นไม้น้อยใหญ่ ซึ่งตัดกันได้ดีกับดอกทิวลิปหลากสีสัน ทำให้เราสามารถเดินเล่นสัมผัสบรรยากาศผ่อนคลายได้ตลอดวันเลยล่ะค่ะ</p>
      <hr>
      <div class="mt-3 mb-5">
        <h5>Tags</h5>
        <div class="is-divider" />
        <h5>
          <b-badge variant="primary" class="mr-2"><i class="fal fa-tags" />&nbsp;ท่องเที่ยว</b-badge>
          <b-badge variant="primary" class="mr-2"><i class="fal fa-tags" />&nbsp;ต่างประเทศ</b-badge>
          <b-badge variant="primary" class="mr-2"><i class="fal fa-tags" />&nbsp;เทศกาล</b-badge>
        </h5>
      </div>
    </b-container>
  </div>
</template>

<script>
import socialSharing from 'vue-social-sharing'
export default {
  components: {
    socialSharing
  },
  data () {
    return {
      images: [
        'http://lorempixel.com/1000/1000/business/1/',
        'http://lorempixel.com/1000/1000/business/2/',
        'http://lorempixel.com/1000/1000/business/3/'
      ]
    }
  }
}
</script>

<style>
  .is-divider {
    height: 3px;
    display: block;
    background-color: #f50;
    margin: 1em 0 1em;
    width: 100%;
    max-width: 30px;
  }
  .line-color{
    color: #03bf22;
  }
  .facebook-color{
    color: #1877f2;
  }
  .twitter-color{
    color: #1da1f3;
  }
  .what-app-color{
    color: #1ad741;
  }
</style>
