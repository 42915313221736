
<template>
  <div class="my-5 pb-5" >
    <div style="margin-top: 70px !important" class="bg-danger">
    </div>

    <img v-if="banner != ''" :src="url+banner" alt="" srcset="" width="100%">
    <div class="clearfix"></div>

    <b-container class="pt-5">
      <b-row>

        <b-col cols="12" class="mb-5">
          <div>
            <h3 class="mb-1">{{ name }}</h3>
            <div class="short--line"></div> <br>
          </div>
        </b-col>

        <b-col md="3" v-for="item in data" :key="item._id">

      
          <b-card
            :title="item.name"
            :img-src="url+item.image"
            class="shadow pointer"
            img-alt="Image"
            img-top 
            @click="redirectPage(item.link)">
          
          </b-card>


        </b-col>
      </b-row>
    </b-container>

    

  

  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'home',
  data () {
    return {
      data: [],
      name: "",
      banner: "",
      url: process.env.VUE_APP_DOMAIN_IMAGE
    }
  },
  methods: {
    ...mapActions('website', ['getHistoryList']),
    viewHistory ( id ) {
      this.$router.push(`/radio/history/${id}`)
    },
    redirectPage (link) {
      window.open(link, '_blank')
    }
    
  },
  async mounted () {
    
    let res = await this.getHistoryList({
      id: this.$route.params.id
    });

    console.log(res);

    if ( res.success && res.data != null ) {  
      this.data = res.data.history_id;
      this.name =  res.data.name;
      this.banner = res.data.banner;
    }

  }
}
</script>

<style >


  .pointer {
    cursor: pointer;
  }
  .short--line {
    width: 50px;
    height: 2px;
    background: #f26726;
  }
</style>

<style scoped>
.txt--date {
  font-size: 13px;
  color: #aca9a9;
}
</style>      
